import React, { useEffect, useState } from "react";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";
import { userActions } from "../actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import PasswordInput from "./common/PasswordInput";
import { oktaDefaultConfig } from "../okta/oktaDefaultConfig";
import { useOktaAuth } from "@okta/okta-react";
import { emailDomainAuthenticatorService } from "../services/emailDomainAuthenticator.service";

function Login({ onOktaDetected, oktaConfigUpdated }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const { oktaAuth } = useOktaAuth();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("returnUrl") ?? "/dashboard";

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); // Step 1: Email, Step 2: Password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNextStep = async () => {
    setSubmitted(true);
    setEmailError("");

    if (!email) {
      setEmailError("Please provide your email.");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // Move to step 2 or redirect to correspondent authenticator
    setLoading(true);
    emailDomainAuthenticatorService
      .checkDomain({ emailDomain: email.split("@")[1] })
      .then(
        (response) => {
          switch (response.data.authenticatorType) {
            case "Okta":
              let oktaConfig = oktaDefaultConfig;
              oktaConfig.issuer = response.data.oktaIssuerUrl;
              oktaConfig.clientId = response.data.oktaClientId;
              onOktaDetected(oktaConfig);
              break;
            default:
              setStep(2);
              setLoading(false);
              break;
          }
        },
        () => {
          setStep(2);
          setLoading(false);
        }
      );
  };

  useEffect(() => {
    if (oktaConfigUpdated) {
      oktaAuth.signInWithRedirect({
        originalUri: redirectUrl,
        loginHint: email,
        prompt: "login",
      });
    }

    // eslint-disable-next-line
  }, [oktaConfigUpdated]);

  const handleLogin = async () => {
    setPasswordError(false);

    if (!password) {
      setPasswordError(true);
      return;
    }

    dispatch(userActions.login(email, password));
  };

  const handleChangeEmail = () => {
    setEmail("");
    setStep(1);
    dispatch(userActions.clearLoginError());
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      if (step === 1) {
        handleNextStep();
      } else {
        handleLogin();
      }
    }
  };

  return (
    <div className="centered-div">
      <Container
        className="login-box bg-white p-5 rounded border border-secondary mt-5"
        onKeyDown={onKeyDown}
      >
        <div className="text-center mb-5">
          <img
            src={require("../assets/armanino-llp.png")}
            alt="Armanino"
            width={250}
          />
        </div>

        {user.loginFailed && (
          <Alert variant={"danger"}>The email or password is incorrect</Alert>
        )}

        {step === 1 ? (
          <>
            <div className="mb-3">
              Please enter your email address to continue.
            </div>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={submitted && emailError ? "is-invalid" : ""}
              />
              {emailError && (
                <div className="invalid-feedback">
                  <small>{emailError}</small>
                </div>
              )}
            </Form.Group>

            <div className="d-grid gap-2 mb-3">
              <Button
                variant="primary"
                className="mt-3"
                size="lg"
                onClick={handleNextStep}
                disabled={loading}
              >
                {!loading ? <>Next</> : <Spinner size="sm" />}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="mb-3">
              Enter your password for <strong>{email}</strong>{" "}
              <Link
                onClick={handleChangeEmail}
                className="text-decoration-none"
              >
                (Change email)
              </Link>
            </div>

            <Form.Group controlId="formBasicPassword" className="mb-3">
              <PasswordInput
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                additionalCss={passwordError ? "is-invalid" : ""}
              />
              {passwordError && (
                <div className="invalid-feedback">
                  <small>Please provide your password.</small>
                </div>
              )}
            </Form.Group>

            <div className="d-grid gap-2 mb-3">
              <Button
                variant="primary"
                className="mt-3"
                size="lg"
                onClick={handleLogin}
                disabled={user.loading}
              >
                {!user.loading ? <>Login</> : <Spinner size="sm" />}
              </Button>
            </div>
          </>
        )}
        <div className="text-center">
          <Link to={"/sign-in-help"} className="text-decoration-none">
            Forgot your password?
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default Login;
