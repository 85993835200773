import { http } from "../api/http";
import Utils from "../utils";

export const emailDomainAuthenticatorService = {
  checkDomain,
};

async function checkDomain(requestParameters) {
  let url = `/auth/check-domain?${Utils.objectToQueryString(
    requestParameters
  )}`;

  const response = await http.get(url);
  return response;
}
