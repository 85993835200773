import React from "react";
import UserMenu from "../user/userMenu";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import { userActions } from "../../actions/user.actions";
import { useDispatch } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

function Header(props) {
  const user = props.user;
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const handleSignOut = async () => {
    try {
      await oktaAuth.signOut();
    } catch {}

    dispatch(userActions.logout());
  };

  return (
    <>
      {user && (
        <Navbar className="bg-grey-300 p-0 pb-1">
          <Container>
            <Nav.Item>
              <Link to="/dashboard">
                <img
                  src={require("../../assets/armanino-llp.png")}
                  alt="Armanino"
                  width={100}
                />
              </Link>
            </Nav.Item>
            <UserMenu user={user} onSignOut={handleSignOut} />
          </Container>
        </Navbar>
      )}
    </>
  );
}
export default Header;
