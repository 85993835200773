import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../utils";
import { invitationActions } from "../../actions/invitation.actions";
import Grid from "../common/Grid";
import Title from "../common/Title";
import { MdGppGood, MdInsertInvitation } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import { Alert, Toast, ToastContainer } from "react-bootstrap";
import { isExpired } from "react-jwt";
import GridHelper from "../helpers/gridHelper";

function InvitationsGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const invitationReducer = useSelector((state) => state.invitation);
  const [displayToast, setDisplayToast] = useState(false);
  const [lastInvitationUrl, setLastInvitationUrl] = useState("");

  const actions = [
    {
      text: "Copy Invitation Url",
      event: (id) => {
        const siteUrl = window.location.origin;
        const invitationUrl = `${siteUrl}/register?t=${id}`;
        setLastInvitationUrl(invitationUrl);
        navigator.clipboard.writeText(invitationUrl).then(() => {
          setDisplayToast(true);
        });
      },
      icon: <FaCopy className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["senderUserId"] = props.user.userId;
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(invitationActions.getBySender(queryParams));
    // eslint-disable-next-line
  }, [location]);

  let displayColumns = ["email", "createdOn"];

  const FormattedDateWithStatus = ({ date, token }) => {
    const tokenExpired = isExpired(token);
    const statusClass = tokenExpired ? "text-danger" : "text-success";
    const statusText = tokenExpired ? "Expired" : "Active";

    return (
      <>
        {date} <small className={statusClass}>({statusText})</small>
      </>
    );
  };

  const data = invitationReducer.paginatedResult?.data.map((i) => {
    return {
      ...i,
      createdOn: (
        <FormattedDateWithStatus
          date={Utils.convertToDateTime(i.createdOn)}
          token={i.token}
        />
      ),
    };
  });

  const loading = invitationReducer.loadingGrid;

  return (
    <>
      <Title
        text="Invitations"
        icon={<MdInsertInvitation className="mb-1" />}
      />

      <Alert variant="info">
        This list includes invitations sent in the last month.
      </Alert>

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"invitationId"}
        actions={actions}
        pageNumber={invitationReducer.paginatedResult?.pageNumber ?? 0}
        totalPages={invitationReducer.paginatedResult?.totalPages ?? 0}
        loading={loading}
        onPageChange={GridHelper.handlePageClick}
        onSort={GridHelper.handleSortClick}
      ></Grid>

      <ToastContainer
        className="p-3"
        position={"bottom-end"}
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() => setDisplayToast(false)}
          show={displayToast}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">
              <MdGppGood className="me-2 mb-1 text-success" /> Link copied to
              clipboard successfully!
            </strong>
            <small>Now</small>
          </Toast.Header>
          <Toast.Body>
            <p>Invitation link is now in your clipboard ready to be shared.</p>
            <p>
              <small>{lastInvitationUrl}</small>
            </p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default InvitationsGrid;
