import { Security, useOktaAuth } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Container, Spinner } from "react-bootstrap";
import { userService } from "../services/user.service";

function OktaAuthWrapper({ children, oktaConfig }) {
  const oktaConfigured = oktaConfig && oktaConfig.issuer && oktaConfig.clientId;
  const oktaAuth = oktaConfigured ? new OktaAuth(oktaConfig) : undefined;

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(originalUri || "/");
  };

  return (
    <>
      {oktaConfigured ? (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <OktaSubWrapper>{children}</OktaSubWrapper>
        </Security>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

function OktaSubWrapper({ children }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [authenticating, setAuthenticating] = useState(false);
  const [error, setError] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const redirectUrl = searchParams.get("returnUrl");

  useEffect(() => {
    const handleAuth = async () => {
      if (authState?.isAuthenticated && authState.accessToken) {
        if (redirectUrl) {
          setAuthenticating(true);
        }

        const userInfo = await oktaAuth.getUser();

        const user = {
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          email: userInfo.email,
        };

        userService.oktaLogin(user, authState.accessToken.accessToken).then(
          (response) => {
            Cookies.set("user", JSON.stringify(response));
            if (redirectUrl) {
              window.location.href = redirectUrl;
            }
          },
          () => {
            setError(true);
          }
        );
      }
    };

    if (!Cookies.get("user")) handleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, oktaAuth]);

  return (
    <>
      {!authenticating ? (
        <>{children}</>
      ) : (
        <div className="centered-div">
          <Container className="login-box bg-white p-5 rounded border border-secondary mt-5">
            <div className="text-center mb-5">
              <img
                src={require("../assets/armanino-llp.png")}
                alt="Armanino"
                width={250}
              />

              <div className="mt-5">
                {!error ? (
                  <>
                    Authenticating <Spinner className="ms-3" size="sm" />
                  </>
                ) : (
                  <span className="text-danger">
                    An error has occurred and we can not authenticate your email
                    right now.
                  </span>
                )}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

export default OktaAuthWrapper;
