import axios from "axios";
import config from "../environments/config";
import Cookies from "js-cookie";

let instance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
    Authorization: Cookies.get("user")
      ? `Bearer ${JSON.parse(Cookies.get("user")).token}`
      : "",
  },
});

// If JWT is expired, clear the cookie and redirect to login
instance.interceptors.request.use(
  (config) => {
    const token = config.headers.Authorization.split(" ")[1];
    if (token) {
      const expiration = JSON.parse(atob(token.split(".")[1])).exp;
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (expiration < currentTimestamp) {
        Cookies.remove("user");
        Cookies.remove("oktaConfig");
        window.location.href = "/login";
        return Promise.reject("Token expired");
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const http = instance;
