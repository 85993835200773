import { userConstants } from "../constants/user.constants";

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loading: true,
        loginFailed: false,
      };
    case userConstants.LOGIN_SUCCESS:
      return state;
    case userConstants.LOGIN_FAILURE:
      return {
        loading: false,
        user: undefined,
        loginFailed: true,
      };
    case userConstants.LOGOUT_REQUEST:
      return {
        loading: true,
      };
    case userConstants.LOGOUT_SUCCESS:
    case userConstants.LOGOUT_FAILURE:
      return {
        loading: false,
      };
    case userConstants.CREATE_REQUEST:
      return {
        loading: true,
        createFailed: false,
        createSuccess: false,
      };
    case userConstants.CREATE_SUCCESS:
      return {
        loading: false,
        createFailed: false,
        createSuccess: true,
      };
    case userConstants.CREATE_FAILURE:
      return {
        loading: false,
        createFailed: true,
        createSuccess: false,
      };
    case userConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        getFailed: false,
        updateSuccess: false,
      };
    case userConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
        getFailed: false,
      };
    case userConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
        paginatedResult: undefined,
        getFailed: true,
      };
    case userConstants.GET_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        getFailed: false,
        updateSuccess: false,
      };
    case userConstants.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userById: action.payload,
        getFailed: false,
      };
    case userConstants.GET_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        userById: undefined,
        getFailed: true,
      };
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        updateFailed: false,
        updateSuccess: false,
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateFailed: false,
        updateSuccess: true,
      };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        updateFailed: true,
        updateSuccess: false,
      };
    case userConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        removeFailed: false,
        removeSuccess: false,
      };
    case userConstants.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        removeFailed: false,
        removeSuccess: true,
      };
    case userConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        removeFailed: true,
        removeSuccess: false,
      };
    case userConstants.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginFailed: false,
      };
    default:
      return state;
  }
}
