import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import NoPage from "./components/common/NoPage";
import Layout from "./components/common/Layout";
import LogInRoute from "./components/routes/LogInRoute";
import PrivateRoute from "./components/routes/PrivateRoute";
import RunbookGrid from "./components/runbook/RunbookGrid";
import RunbookExecutionHistoryGrid from "./components/runbook/RunbookExecutionHistoryGrid";
import RunbookExecutionHistoryLineGrid from "./components/runbook/RunbookExecutionHistoryLineGrid";
import "./custom.scss";
import "./App.css";
import Cookies from "js-cookie";
import SchedulerGrid from "./components/scheduler/SchedulerGrid";
import SignInHelp from "./components/user/SignInHelp";
import Register from "./components/user/Register";
import SchedulerForm from "./components/scheduler/SchedulerForm";
import { settingsActions } from "./actions/settings.actions";
import VariableGrid from "./components/variable/VariableGrid";
import VariableForm from "./components/variable/VariableForm";
import RunbookDetails from "./components/runbook/RunbookDetails";
import Profile from "./components/user/Profile";
import ConnectorGrid from "./components/connector/ConnectorGrid";
import ConnectorForm from "./components/connector/ConnectorForm";
import ClientGrid from "./components/client/ClientGrid";
import ClientForm from "./components/client/ClientForm";
import VariableLineGrid from "./components/variable/VariableLineGrid";
import VariableLineForm from "./components/variable/VariableLineForm";
import BroadcastMessageGrid from "./components/broadcastMessage/BroadcastMessageGrid";
import BroadcastMessageForm from "./components/broadcastMessage/BroadcastMessageForm";
import UsersGrid from "./components/user/UsersGrid";
import UserForm from "./components/user/UserForm";
import RunbookConnectorGrid from "./components/runbook/RunbookConnectorGrid";
import BroadcastMessageReviewGrid from "./components/broadcastMessage/BroadcastMessageReviewGrid";
import InvitationsGrid from "./components/invitations/InvitationsGrid";
import RunbookForm from "./components/runbook/RunbookForm";
import OktaAuthWrapper from "./okta/oktaAuthWrapper";
import { oktaDefaultConfig } from "./okta/oktaDefaultConfig";
import { LoginCallback } from "@okta/okta-react";

function App() {
  const dispatch = useDispatch();
  const [oktaConfigUpdated, setOktaConfigUpdated] = useState(false);

  const handleOktaDetected = (newOktaConfig) => {
    Cookies.set("oktaConfig", JSON.stringify(newOktaConfig));
    setOktaConfigUpdated(true);
  };

  const user = Cookies.get("user")
    ? JSON.parse(Cookies.get("user"))
    : undefined;

  const oktaConfig = Cookies.get("oktaConfig")
    ? JSON.parse(Cookies.get("oktaConfig"))
    : oktaDefaultConfig;

  useEffect(() => {
    if (user && user.isSuperUser) dispatch(settingsActions.get());
    // eslint-disable-next-line
  }, [user]);

  return (
    <OktaAuthWrapper oktaConfig={oktaConfig}>
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={
              <LogInRoute
                onOktaDetected={handleOktaDetected}
                oktaConfigUpdated={oktaConfigUpdated}
              />
            }
          />
          <Route
            path="login"
            element={
              <LogInRoute
                onOktaDetected={handleOktaDetected}
                oktaConfigUpdated={oktaConfigUpdated}
              />
            }
          />
          <Route path="callback" element={<LoginCallback />} />
          <Route path="sign-in-help" element={<SignInHelp />} />
          <Route path="register" element={<Register />} />
          <Route path="/" element={<Layout user={user} />}>
            <Route
              path="/profile"
              element={
                <PrivateRoute user={user}>
                  <Profile user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute user={user}>
                  <Dashboard user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/runbook"
              element={
                <PrivateRoute user={user}>
                  <RunbookGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/runbook/executions"
              element={
                <PrivateRoute user={user}>
                  <RunbookExecutionHistoryGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/runbook/executions/lines"
              element={
                <PrivateRoute user={user}>
                  <RunbookExecutionHistoryLineGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/runbook/details"
              element={
                <PrivateRoute user={user}>
                  <RunbookDetails user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/runbook/add"
              element={
                <PrivateRoute user={user}>
                  <RunbookForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/runbook/edit"
              element={
                <PrivateRoute user={user}>
                  <RunbookForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/scheduler"
              element={
                <PrivateRoute user={user}>
                  <SchedulerGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/scheduler/add"
              element={
                <PrivateRoute user={user}>
                  <SchedulerForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/scheduler/edit"
              element={
                <PrivateRoute user={user}>
                  <SchedulerForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/runbook/connector"
              element={
                <PrivateRoute user={user}>
                  <RunbookConnectorGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/variable"
              element={
                <PrivateRoute user={user}>
                  <VariableGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/variable/add"
              element={
                <PrivateRoute user={user}>
                  <VariableForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/variable/edit"
              element={
                <PrivateRoute user={user}>
                  <VariableForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/variable/line"
              element={
                <PrivateRoute user={user}>
                  <VariableLineGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/variable/line/edit"
              element={
                <PrivateRoute user={user}>
                  <VariableLineForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/variable/line/add"
              element={
                <PrivateRoute user={user}>
                  <VariableLineForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/connector"
              element={
                <PrivateRoute user={user}>
                  <ConnectorGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/connector/edit"
              element={
                <PrivateRoute user={user}>
                  <ConnectorForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/connector/add"
              element={
                <PrivateRoute user={user}>
                  <ConnectorForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/user"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <UsersGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/edit"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <UserForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/client"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <ClientGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/client/renew"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <ClientForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/client/add"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <ClientForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <BroadcastMessageGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages/add"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <BroadcastMessageForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages/edit"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <BroadcastMessageForm user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages/reviews"
              element={
                <PrivateRoute user={user} onlySuperUser={true}>
                  <BroadcastMessageReviewGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="/invitation"
              element={
                <PrivateRoute user={user} onlyAdmin={true}>
                  <InvitationsGrid user={user} />
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={
                <PrivateRoute user={user}>
                  <NoPage />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </OktaAuthWrapper>
  );
}

export default App;
